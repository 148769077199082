import { createRouter, createWebHistory } from 'vue-router'


const routes = [
   {
    path : "/order",
    name : "order",
    component : () => import("../views/order.vue")


   },


  {
    path: '/:section',
    name: 'indexsection',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },

  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
